import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import ServicesData from '../../content/services-content.json';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';

const Home = (props) => {
  const image = props.data;
  return (
    <Layout bodyClass="page-home">
      <SEO title="Home" />
      <Helmet>
        <meta
          name="description"
          content="TSN Building Contractors Ltd is a well established family run construction company based in Redbridge specialising in extensions, renovations, loft conversions and all types of domestic and commercial building work."
        />
      </Helmet>
      <div className="intro p-0 mt-6">
        <BackgroundImage className="background-image" fluid={image.bgImage.childImageSharp.fluid} alt={image.bgImage.childImageSharp.alt}>
          <div className="overlay">
            <div className="container">
              <h1>TSN Building Contractors Ltd</h1>
            </div>
          </div>  
        </BackgroundImage>
      </div>

      <div className="container pt-8 pt-md-10">
        <div className="row justify-content-start">
          <div className="col-12">
            <h2 className="title-home text-dark mb-3">About Us</h2>
            <p>TSN Building Contractors Ltd (formerly of Notcon Builders &amp; Contractors Ltd) is a family run business with over 35 years of experience in construction and renovation of commercial and residential properties. Based in East London, we operate across South-East London and within Central London. A large percentage of our business has been generated by word of mouth due to the quality of our work and high level of customer satisfaction.</p>
            <p>We pride ourselves in providing reliable and professional services for all types of building projects by putting the customer first and working together to achieve their aspirations.</p>
          </div>
        </div>
      </div>

      <div className="container pt-5 pb-5 pt-md-7 pb-md-7">
        <div className="row justify-content-start">
          <div className="col-12">
            <h2 className="title-home text-dark mb-3">Our Services</h2>
          </div>
          {ServicesData.content.map((data, index) => {
            return <div key={`content_item_${index}`} className="col-12 col-md-4 mb-1">
              <div className="card service service-teaser">
                <div className="card-content">
                  <p className="service-title">{data.title}</p>
                  <p>{data.description}</p>
                </div>
              </div>
            </div>
          })}
        </div> 
      </div>

      <div className="container pb-5 pb-md-7">
        <div className="row justify-content-center">
          <div className="center-block">
            <Img fixed={image.fmbLogo.childImageSharp.fixed} alt={image.fmbLogo.childImageSharp.alt} />
          </div>
        </div>
      </div>

    </Layout>
  );
};

export const query = graphql`
  query imageQuery {
    bgImage: file(relativePath: {eq: "home-bg.jpg"}) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fmbLogo: file(relativePath: {eq: "fmb-logo.png"}) {
      childImageSharp {
        fixed(width: 150, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default Home;
